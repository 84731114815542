<template>
  <div>
    <!-- <b-form-group
      label="Typo de Acta"
      label-for="select-act-type"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="select-act-type"
        :options="ActTypes"
        value-field="id"
        text-field="name"
        v-model="$v.act.act_type.$model"
        :state="validateState('act_type')"
        :disabled="!allows_crud"
        aria-describedby="select-act-type-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="select-act-type-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}`"
      label-for="input-egress-profile"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-egress-profile"
        :options="egressProfiles"
        value-field="id"
        text-field="academic_degree"
        v-model="$v.act.egress_profile.$model"
        :state="validateState('egress_profile')"
        :disabled="!allows_crud"
        aria-describedby="input-egress-profile-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-egress-profile-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.campus', false, 'Sede')"
      label-for="input-campus"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-campus"
        :options="campuses"
        value-field="id"
        text-field="name"
        v-model="$v.act.campus.$model"
        :state="validateState('campus')"
        :disabled="!allows_crud"
        aria-describedby="input-campus-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-campus-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.period', false, 'Período')"
      label-for="input-period"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-period"
        :options="periods"
        value-field="id"
        text-field="name"
        v-model="$v.act.period.$model"
        :state="validateState('period')"
        :disabled="!allows_crud"
        aria-describedby="input-period-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-period-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <!-- <b-form-group
      :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
      label-for="input-shift"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-shift"
        :options="shifts"
        value-field="id"
        text-field="name"
        v-model="$v.act.shift.$model"
        :state="validateState('shift')"
        :disabled="!allows_crud"
        aria-describedby="input-shift-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-shift-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <!-- <b-form-group
      label="Nivel"
      label-for="input-level"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-level"
        :options="levels"
        value-field="id"
        text-field="name"
        v-model="$v.act.level.$model"
        :state="validateState('level')"
        :disabled="!allows_crud"
        aria-describedby="input-level-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-level-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group> -->
    <b-form-group
      label="Fecha de Inicio"
      label-for="input-startdate"
      label-cols="0"
      label-cols-sm="3"
      class="p-0"
    >
      <div class="data-input">
        <b-form-input
          id="input-startdate"
          v-model="$v.act.start_date.$model"
          :state="validateState('start_date')"
          :disabled="!allows_crud"
          aria-describedby="input-startdate-feedback"
          size="sm w-75 mr-1"
          hidden
        ></b-form-input>
        <b-form-datepicker
          :min="min"
          :max="max"
          v-model="start_date"
        ></b-form-datepicker>
        <b-form-timepicker v-model="start_time"></b-form-timepicker>
      </div>
      <b-form-invalid-feedback id="input-startdate-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Fecha de Término"
      label-for="input-enddate"
      label-cols="0"
      label-cols-sm="3"
      class="p-0"
    >
      <div class="data-input">
        <b-form-input
          id="input-enddate"
          v-model="$v.act.end_date.$model"
          :state="validateState('end_date')"
          :disabled="!allows_crud"
          aria-describedby="input-enddate-feedback"
          size="sm w-75 mr-1"
          hidden
        ></b-form-input>
        <b-form-datepicker
          :min="min"
          :max="max"
          v-model="end_date"
        ></b-form-datepicker>
        <b-form-timepicker v-model="end_time"></b-form-timepicker>
      </div>
      <b-form-invalid-feedback id="input-enddate-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(act.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="deleteAct"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "ActForm",
  mixins: [validationMixin],
  props: {
    Act: {
      type: Object,
    },
    ActTypes: {
      type: Array,
      required: true,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    period_id: {
      type: Number,
      required: true,
    },
    campus_id: {
      type: Number,
      required: true,
    },
    level: {
      // type: Number,
      // required: true,
    },
    act_type: {
      type: Number,
      required: true,
    },
    shift_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      start_date: null,
      start_time: null,
      end_date: null,
      end_time: null,
      act: {
        id: this.Act ? this.Act.id : generateUniqueId(),
        egress_profile: this.Act
          ? this.Act.egress_profile
          : this.egress_profile_id,
        period: this.Act ? this.Act.period : this.period_id,
        level: this.Act ? this.Act.level : this.level,
        campus: this.Act ? this.Act.campus : this.campus_id,
        start_date: this.Act ? this.Act.start_date : null,
        end_date: this.Act ? this.Act.end_date : null,
        act_type: this.Act ? this.Act.act_type : this.act_type,
        shift: this.Act ? this.Act.shift : this.shift_id,
      },
    };
  },
  validations() {
    return {
      act: {
        egress_profile: { required },
        campus: { required },
        period: { required },
        // level: { required },
        start_date: { required },
        end_date: { required },
        professors: {},
        act_type: { required },
        shift: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      sections: names.SECTIONS,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      return this.careers.find((x) => x.id == this.career_id);
    },
    min() {
      const period = this.periods.find(
        (x) => x.id == this.act.period
      ).start_date;
      return period ? period : null;
    },
    max() {
      const period = this.periods.find((x) => x.id == this.act.period).end_date;
      return period ? period : null;
    },
    levels() {
      let min = 1;
      let max = 14;
      if (this.cycle) {
        min = this.cycle.init_semester;
        max = this.cycle.end_semester;
      }
      if (this.egressProfile) max = this.egressProfile.semester_amount;
      let list = [];
      for (let i = min; i <= max; i++) {
        list.push(i);
      }
      return list;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.act[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.act.$touch();
      if (this.$v.act.$anyError) return;
      if (isNaN(this.act.id)) this.createAct();
      else this.updateAct();
    },
    createAct() {
      this.$restful.Post("/cideu/act/", this.act).then((response) => {
        this.act = response;
        this.$emit("created", response);
        toast("Acta creada.");
      });
    },
    updateAct() {
      this.$restful
        .Put(`/cideu/act/${this.act.id}/`, this.act)
        .then((response) => {
          this.act = response;
          this.$emit("updated", response);
          toast("Acta actualizada.");
        });
    },
    formatStartDate() {
      try {
        this.act.start_date = this.start_date + " " + this.start_time;
        // this.act.start_date = this.$moment(
        //   this.start_date + " " + this.start_time
        // ).format("YYYY-MM-DD HH:MM");
      } catch (error) {
        console.log(error);
      }
    },
    formatEndDate() {
      try {
        this.act.end_date = this.end_date + " " + this.end_time;
        // this.act.end_date = this.$moment(
        //   this.end_date + " " + this.end_time
        // ).format("YYYY-MM-DD HH:MM");
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    start_date() {
      this.formatStartDate();
    },
    start_time() {
      this.formatStartDate();
    },
    end_date() {
      this.formatEndDate();
    },
    end_time() {
      this.formatEndDate();
    },
  },
  mounted() {
    this.start_date = this.min;
    this.end_date = this.max;
  },
  created() {
    let now = this.$moment();
    if (this.act.start_date) {
      console.log(this.$moment(this.act.start_date).format("YYYY-MM-DD HH:MM"));
    } else {
      // this.start_date = now.format("YYYY-MM-DD");
      this.start_time = now.format("HH:MM");
      now = now.add(1, "hours");
      // this.end_date = now.format("YYYY-MM-DD");
      this.end_time = now.format("HH:MM");
    }
    if (this.act.end_date) {
      console.log(this.$moment(this.act.end_date).format("YYYY-MM-DD HH:MM"));
    }
  },
};
</script>

<style scoped>
.data-input {
  display: flex;
  flex-direction: row;
}
</style>